import { FC } from 'react';
import { head } from 'ramda';
import getConfig from 'next/config';
import { HomePayVisitorHeader } from '@care/navigation';
import {
  HomePayVisitorHeaderBrand,
  HomePayVisitorHeaderLink,
} from '@care/navigation/dist/components/types';
import { Box } from '@mui/material';
import { IconLogoHomepaylogo } from '@care/react-icons';
import { CLIENT_FEATURE_FLAGS, PHONE_LABEL, PHONE_LINK, ROUTING_PROPERTIES } from '@/constants';
import { useUserAuth } from '@/components/UserProvider';
import { NextConfig } from '@/interfaces';
import { AmplitudeEventName, logAnalyticsEvent, PageProperties } from '@/utils/AnalyticsHelper';
import { getEnrollmentUrl } from '@/utils/urlHelper';
import usePartnerInfo from '@/utils/usePartnerInfo';
import { useFeatureFlags } from '@/components/contexts/FeatureFlagsContext';
import { useGetHeaderLeftSideLinks } from '@/utils/hooks';

interface Props {
  isMobile: boolean;
  branding?: HomePayVisitorHeaderBrand;
  leftLinks?: HomePayVisitorHeaderLink[];
  pageProperties: PageProperties;
}

const { publicRuntimeConfig } = getConfig() as NextConfig;

const { AUTH_LOGOUT_URL, BREEDLOVE_LOGIN_URL, HOMEPAY_URL, CORE_BASE_URL } = publicRuntimeConfig;

const Header: FC<Props> = ({ isMobile, branding, leftLinks, pageProperties }) => {
  const { auth } = useUserAuth();
  const partnerInfo = usePartnerInfo();
  const { flags } = useFeatureFlags();
  const logoUrl =
    flags[CLIENT_FEATURE_FLAGS.TTP_ASSET_URL]?.value || `${CORE_BASE_URL}/s/d/ttp/care-logo.svg`;
  const leftSideLinks = useGetHeaderLeftSideLinks(isMobile, publicRuntimeConfig, leftLinks);

  const handleLoginClick = () => {
    logAnalyticsEvent({
      data: {
        cta_location: 'Header',
        cta_text: 'LogIn',
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties,
    });
    window.location.assign(BREEDLOVE_LOGIN_URL);
  };

  const handleGetStarted = () => {
    logAnalyticsEvent({
      data: {
        cta_location: 'Header',
        cta_text: 'GetStarted',
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties,
    });
    window.location.assign(getEnrollmentUrl(undefined, undefined, partnerInfo?.name));
  };

  const handleLogoutClick = () => {
    logAnalyticsEvent({
      data: {
        cta_location: 'Header',
        cta_text: 'logout (menu)',
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties,
    });
    const redirectUri = encodeURIComponent(`${window.location.origin}/homepay`);
    window.location.assign(`${AUTH_LOGOUT_URL}?returnUrl=${redirectUri}`);
  };

  const getAuthLinks = (): HomePayVisitorHeaderLink[] => {
    if (auth?.isLoggedIn && auth?.member) {
      const { displayName, firstName, lastName } = auth.member;
      const name = firstName && lastName ? `${firstName} ${head(lastName)}` : displayName;
      return [
        {
          text: `Hi, ${name}`,
          type: 'text',
        },
        {
          href: '#',
          text: 'Log Out',
          type: 'link',
          onClick: handleLogoutClick,
        },
      ] as HomePayVisitorHeaderLink[];
    }

    if (isMobile) {
      return [
        {
          href: '#',
          variant: 'contained',
          color: 'secondary',
          text: 'Log In',
          type: 'button',
          onClick: handleLoginClick,
        },
      ] as HomePayVisitorHeaderLink[];
    }

    return [
      {
        href: '#',
        text: 'Log In',
        type: 'link',
        onClick: handleLoginClick,
      },
    ] as HomePayVisitorHeaderLink[];
  };

  const brandLink: HomePayVisitorHeaderBrand = branding ?? {
    href: `${HOMEPAY_URL}${ROUTING_PROPERTIES.mainVHP.relativeURL}`,
    logo: <IconLogoHomepaylogo height="58px" width="200px" />,
  };

  const rightSideLinks: HomePayVisitorHeaderLink[] = [
    {
      text: PHONE_LABEL,
      href: PHONE_LINK,
      type: 'link',
      color: 'initial',
    },
    ...getAuthLinks(),
  ];

  return isMobile ? (
    <Box
      sx={{
        '& .MuiGrid-root': {
          '@media (max-width: 425px)': {
            width: 250,
          },
        },
      }}>
      <HomePayVisitorHeader
        branding={brandLink}
        isMobile
        leftLinks={leftSideLinks}
        altLogoUrl={logoUrl}
        rightLinks={rightSideLinks}
        hasNoGetStartedCTA
      />
    </Box>
  ) : (
    <HomePayVisitorHeader
      branding={brandLink}
      isMobile={false}
      leftLinks={leftSideLinks}
      altLogoUrl={logoUrl}
      rightLinks={rightSideLinks}
      onGetStartedClick={handleGetStarted}
    />
  );
};

export default Header;
